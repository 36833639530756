import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';

import useWindowSize from '../../utils/useWindowSize';
import PageTitle from '../../components/page-title/PageTitle';
import Partners from '../../components/partners/Partners';
import ContactForm from '../../components/contact-form/ContactForm';
import DownloadModal from '../../components/download-modal/DownloadModal';
import TestimonialsCarousel from './TestimonialsCarousel';
import styles from './index.module.scss';
import './home.scss';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function Home(props) {
    // ------------------------------------------------------------------------------------

    // HERO IMAGES

    const introImageUrl = './images/backgrounds/backgrounds-assets/intro_background';
    const aboutImageUrl = './images/backgrounds/backgrounds-assets/about_background';
    const curriculumImageUrl = './images/backgrounds/backgrounds-assets/curriculum_background';
    const careersImageUrl = './images/backgrounds/backgrounds-assets/careers_background';
    const characterImageUrl = './images/backgrounds/backgrounds-assets/character_background';
    const partnersImageUrl = './images/backgrounds/backgrounds-assets/partners_background';
    const partnersImageUrl2 = './images/backgrounds/backgrounds-assets/partners_background2';
    const impactImageUrl = './images/backgrounds/backgrounds-assets/impact_background';
    const contactImageUrl = './images/backgrounds/backgrounds-assets/contact_background';
    const videoImageUrl = './images/backgrounds/backgrounds-assets/video_background';
    const partnersImageUrl3 = './images/backgrounds/backgrounds-assets/partners_background3';

    const imagePlatform = useWindowSize()[0] >= 768 ? '_desktop' : '_mobile';

    // ------------------------------------------------------------------------------------

    // INTRO IMAGE ROTATION

    const [headerImageIndex, setHeaderImageIndex] = useState(1);
    const preloadImage = useRef(null);

    useEffect(() => {
        const interval = setInterval(increment, 5000);
        preloadNext(1);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const increment = () => {
        setHeaderImageIndex((currentHeaderImageIndex) => {
            let newIndex = currentHeaderImageIndex;
            newIndex++;
            if (newIndex > 7) newIndex = 1;
            preloadNext(newIndex);
            return newIndex;
        });
    };

    const preloadNext = (nextIndex) => {
        nextIndex += 1;
        if (nextIndex > 7) nextIndex = 1;
        preloadImage.current.src = `./images/backgrounds/backgrounds-assets/intro_slider${nextIndex}.jpg`;
    };

    // ------------------------------------------------------------------------------------

    // ANIMATE TITLES

    const mainRef = useRef(null);

    // ------------------------------------------------------------------------------------

    // DOWNLOAD MODAL

    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const handleDownloadModalClose = () => setShowDownloadModal(false);
    const handleDownloadModalShow = () => setShowDownloadModal(true);

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <div className="MainPage" ref={mainRef}>
            <Image className="preloadImage" ref={preloadImage} style={{ display: 'none' }} />
            {/* // INTRO ---------------------------------------------------------------------------------*/}
            <Container
                fluid
                id="top"
                className={`section ${styles.intro_section} nopadding`}
                style={{
                    backgroundImage: `url(./images/backgrounds/backgrounds-assets/intro_slider${headerImageIndex}.jpg`,
                }}
            >
                <Container className={styles.intro_content}>
                    <Row>
                        <img src={`${introImageUrl}${imagePlatform}.png`} alt="" />
                    </Row>
                </Container>
            </Container>

            {/* // PROGRAMME ---------------------------------------------------------------------------------*/}

            <Container
                id="programme"
                fluid
                className="greenBg section"
                style={{ backgroundImage: `url(${aboutImageUrl}${imagePlatform}.jpg)` }}
            >
                <Container>
                    <Row className={`${imagePlatform}`}></Row>
                    <Row>
                        <Col xs={12} md={6} className="hero_section">
                            <h1>
                                <PageTitle className="title scrollme" title="CHANGING THE GAME" />
                            </h1>
                            <p>
                                We are on the cusp of a crisis that should not be happening. A generation of kids is
                                leaving school unaware of creativity as a career option. And yet, the creative
                                industries are crying out for new voices and perspectives. Supply is real. Demand is
                                real. Upriser by Saatchi & Saatchi exists to bring them together.
                            </p>
                            <p>
                                This programme matchmakes creative companies with UK secondary schools and provides an
                                open-source playbook to facilitate meaningful and sustained partnerships.
                            </p>
                            <p>
                                The ambition: inspire state secondary school students with the power of creative
                                thinking and make them aware about the career opportunities that exist in the creative
                                industries.
                            </p>
                        </Col>
                        <Col xs={12} md={6}></Col>
                    </Row>
                </Container>
            </Container>

            {/* // VIDEO ---------------------------------------------------------------------------------*/}

            <Container
                id="video"
                fluid
                className="midgreyBg section"
                style={{ backgroundImage: `url(${videoImageUrl}${imagePlatform}.jpg)` }}
            >
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className="ratio ratio-16x9">
                                <ReactPlayer
                                    url="https://vimeo.com/781156027"
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="video_title">
                            <h1>
                                <PageTitle className="title" title="Upriser in 60 seconds" />
                            </h1>
                            <p></p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* // BOOM SECTION ---------------------------------------------------------------------------------*/}

            <Container id="boom" fluid className="greyBg section">
                <Container>
                    <Row>
                        <h1>
                            <PageTitle className="title" title="THREE PILLARS OF BOOM" />
                        </h1>
                        <p>A simple, modular and scalable engagement programme.</p>
                    </Row>
                </Container>
            </Container>

            {/* // CURRICULUM ---------------------------------------------------------------------------------*/}

            <Container
                id="curriculum"
                fluid
                className="greenBg section"
                style={{ backgroundImage: `url(${curriculumImageUrl}${imagePlatform}.jpg)` }}
            >
                <Container>
                    <Row className={`${imagePlatform}`}></Row>
                    <Row>
                        <Col xs={12} md={5} className="hero_section">
                            <h1>
                                <PageTitle className="title" title="CURRICULUM BOOM" />
                            </h1>
                            <h4>TEACHER FOCUS</h4>
                            <p>
                                1:1 sessions with teachers troubleshooting curriculum issues – areas where students
                                aren’t engaging, or grasping real-world applications.
                            </p>
                            <h4>LESSON TAKEOVERS</h4>
                            <p>
                                A lesson takeover in a specific curriculum area – helping students apply what they’re
                                learning to the real world with live briefs.
                            </p>
                            <h4>CROSS CURRICULUM</h4>
                            <p>
                                Workshops to bring together different curriculum areas, spotlighting how business and
                                creative projects need the input of multiple disciplines.
                            </p>
                            <Image fluid src="./images/backgrounds/backgrounds-assets/graph_1.png" />
                        </Col>
                        <Col xs={8}></Col>
                    </Row>
                </Container>
            </Container>

            {/* // CAREERS ---------------------------------------------------------------------------------*/}

            <Container
                id="careers"
                fluid
                className="greenBg section"
                style={{ backgroundImage: `url(${careersImageUrl}${imagePlatform}.jpg)` }}
            >
                <Container>
                    <Row className={`${imagePlatform}`}></Row>
                    <Row>
                        <Col xs={12} md={7}></Col>
                        <Col xs={12} md={5} className="hero_section">
                            <h1>
                                <PageTitle className="title" title="CAREER BOOM" />
                            </h1>
                            <h4>Mock interviews</h4>
                            <p>
                                Building confidence and readiness, to ensure students are prepared for their first
                                interview, with mock interviews for entry-level jobs in the creative industries, virtual
                                or in person.
                            </p>
                            <h4>Mentoring</h4>
                            <p>
                                A full year of 1:1 mentoring for students facing particular challenges, so that as they
                                look ahead they aren’t held back. Each mentor is matched with a student, and that
                                mentor-mentee relationship is sustained for six 1:1 sessions across the year: two at
                                school, two at the office, two virtual.
                            </p>
                            <h4>Work experience</h4>
                            <p>
                                Giving students real-world exposure of working in a creative company and being part of a
                                professional creative culture. This can be one of the most impactful ways to open kids’
                                eyes to their own future.
                            </p>
                            <Image fluid src="./images/backgrounds/backgrounds-assets/graph_2.png" />
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* // CHARACTER ---------------------------------------------------------------------------------*/}

            <Container
                id="character"
                fluid
                className="greenBg section"
                style={{ backgroundImage: `url(${characterImageUrl}${imagePlatform}.jpg)` }}
            >
                <Container>
                    <Row className={`${imagePlatform}`}></Row>
                    <Row>
                        <Col xs={12} md={5} className="hero_section">
                            <h1>
                                <PageTitle className="title" title="CHARACTER BOOM" />
                            </h1>
                            <h4>Inspiration sessions </h4>
                            <p>
                                In person or virtual Assembly talks (TED-style) from leaders in the creative industries.
                            </p>
                            <h4>Inspiration days</h4>
                            <p>
                                Full days, curated around community moments, or cultural and creative events – designed
                                to engage students actively in the creative process.
                            </p>
                            <h4>Reward trips </h4>
                            <p>
                                Rewarding aspiration and instilling focus with an on site experience at a vibrant
                                creative company. Getting out of school, shattering preconceptions, fun and inspiring
                                for students and teachers alike.
                            </p>
                            <Image fluid src="./images/backgrounds/backgrounds-assets/graph_3.png" />
                        </Col>
                        <Col xs={8}> </Col>
                    </Row>
                </Container>
            </Container>

            {/* // PARTNERS 1 -------------------------------------------------------------------------------*/}

            <Container
                id="partners"
                fluid
                className="lightgreyBg section"
                style={{ backgroundImage: `url(${partnersImageUrl}${imagePlatform}.jpg)` }}
            >
                <Container>
                    <Row className={`${imagePlatform}`} style={{ height: '230px' }}></Row>
                    <Row>
                        <Col xs="12" lg="2"></Col>
                        <Col xs="12" lg="8">
                            <h1>
                                <PageTitle className="title" title="UPRISE YOUR COMPANY..." />
                            </h1>
                            <p style={{ fontSize: '17px' }}>
                                Are you a British <strong>creative company</strong> keen to plug in and dedicate time
                                and energy to developing the next generation? Key ingredients :
                            </p>
                            <h4>YOUR LEADERSHIP.</h4>
                            <p>
                                You have progressive senior leaders, who can point company resource at the partnership
                                and protect that commitment ongoing
                            </p>
                            <h4>PERSONAL RELATIONSHIPS:</h4>
                            <p>
                                At its heart, this is a passion project bringing three groups of people together
                                (teachers, employees and students). Time to get to know one another is essential,
                                alongside curiosity, empathy and care.
                            </p>
                            <h4>YOUR TIME:</h4>
                            <p>
                                This programme is unique and radical. For it to work, every company has to commit to a 7
                                year partnership, with a minimum of 1,000 staff hours per year.
                            </p>
                            <h4>YOUR COMMITMENT: </h4>
                            <p>
                                Two internal figures are central to the success of the programme - a passionate, engaged
                                HR lead, and an accessible operational lead.
                            </p>
                            <h4>THE CULTURE: </h4>
                            <p>
                                Youth as well as diversity within the company and team are important. There also needs
                                to be informality and flexibility - to bridge cultural differences between
                                organisations, and help ensure a mutual learning experience.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* // PARTNERS 2 -------------------------------------------------------------------------------*/}

            <Container
                id="partners"
                fluid
                className="greyBg section"
                style={{ backgroundImage: `url(${partnersImageUrl2}${imagePlatform}.jpg)` }}
            >
                <Container>
                    <Row className={`${imagePlatform}`} style={{ height: '230px' }}></Row>

                    <Row>
                        <Col xs="12" lg="2"></Col>
                        <Col xs="12" lg="8">
                            <h1>
                                <PageTitle className="title" title="UPRISE YOUR SCHOOL…" />
                            </h1>
                            <p style={{ fontSize: '17px' }}>
                                Are you a British Secondary <strong>State School or Academy</strong> keen to widen pupil
                                horizons, supercharge the curriculum and develop a sustainable careers programme?
                            </p>
                            <p>Key ingredients:</p>

                            <h4>REAL NEED</h4>
                            <p>
                                The programme is aimed at schools with at least one of the following factors:
                                deprivation and disadvantage above the national average, Pupil Premium cohort above
                                national average, diverse communities and/or a large cohort of looked-after kids.
                            </p>
                            <h4>YOUR TIME</h4>
                            <p>
                                School partners need to ring-fence a specific time for the programme (weekly is best) to
                                keep ideas firing and evolve the programme for your needs. Planning events, sharing
                                ideas and feedback will ensure the partnership ignites and the programme lifts off.
                            </p>
                            <h4>YOUR LEADERSHIP</h4>
                            <p>
                                You have innovative senior leaders ready to think creatively about school improvement.
                                The core team needs to be directed to ensure maximum impact on the School Improvement
                                Plan and kids’ experience. This programme is designed to be a sustained programme
                                tailored to your contextual needs.
                            </p>
                            <h4>YOUR COMMITMENT</h4>
                            <p>
                                Two internal figures are central to the success of this programme: a passionate, engaged
                                Senior Leader with oversight of Curriculum or Careers, and a staff member who can look
                                after the administration of trips, events & calendar.
                            </p>
                            <h4>PERSONAL RELATIONSHIPS</h4>
                            <p>
                                At its heart, this programme is a passion project bringing three groups of people
                                together (teachers, employees and students). Time to get to know one another is
                                essential, alongside curiosity, empathy and care.
                            </p>
                            <h4>YOUR CULTURE</h4>
                            <p>
                                The company that partners you will be learning about your school as you learn about
                                their industry. Welcoming them in and enabling their integration will bridge cultural
                                differences between organisations, and help ensure a mutual learning experience.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* // PARTNER LOGOS -------------------------------------------------------------------------------*/}

            <Container
                id="partners"
                fluid
                className="greenBg section "
                style={{ backgroundImage: `url(${partnersImageUrl3}${imagePlatform}.jpg)`, overflow: 'hidden' }}
            >
                <Container>
                    <Row className={`${imagePlatform}`} style={{ height: '200px' }}></Row>
                    <Row className="">
                        <Col xs="12" lg="2"></Col>
                        <Col xs="12" lg="10" className="partner_logos">
                            <Partners />
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* // TESTIMONIALS ---------------------------------------------------------------------------------*/}

            <Container
                id="testimonials"
                fluid
                className="midgreyBg section"
                style={{ backgroundImage: `url(${impactImageUrl}${imagePlatform}.jpg)` }}
            >
                <Container>
                    <Row className={`${imagePlatform}`} style={{ height: '350px' }}></Row>
                    <Row className={`nopadding`}>
                        <Col xs="12" lg="6"></Col>
                        <Col xs="12" lg="6" className={` hero_section nopadding`}>
                            <Row className={` nopadding`}>
                                <TestimonialsCarousel />
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* // DOWNLOAD ---------------------------------------------------------------------------------*/}

            <Container id="download" fluid className="greenBg section" style={{ display: 'none' }}>
                <Container>
                    <Row>
                        <Col xs="12" lg="12">
                            <h1>
                                <PageTitle className="title" title="DOWNLOAD" />
                            </h1>
                            <div>Download our whitepaper here</div>
                            <Button variant="secondary" onClick={handleDownloadModalShow}>
                                Download
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* // CONTACT ---------------------------------------------------------------------------------*/}

            <Container
                id="contact"
                fluid
                className="lightgreyBg section nopadding"
                style={{ backgroundImage: `url(${contactImageUrl}${imagePlatform}.jpg)` }}
            >
                <Container>
                    <Row className={`${imagePlatform}`} style={{ height: '160px' }}></Row>
                    <Row>
                        <Col xs="12" lg="2"></Col>
                        <Col xs="12" lg="8">
                            <div className={styles.form_intro}>
                                If you’re a <strong>creative company</strong> or a <strong>school</strong> who would
                                like to be involved, we’d love to hear from you.
                            </div>
                            <ContactForm closeModal={props.closeModal} showModal={props.showModal} />
                        </Col>
                        <Col xs="12" lg="2"></Col>
                    </Row>
                </Container>
            </Container>

            {/* // DOWNLOAD MODAL ---------------------------------------------------------------------------------*/}

            <DownloadModal
                showDownloadModal={showDownloadModal}
                handleDownloadModalClose={handleDownloadModalClose}
                handleDownloadModalShow={handleDownloadModalShow}
            />

            {/* // END ---------------------------------------------------------------------------------*/}
        </div>
    );
}

export default Home;
