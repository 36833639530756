import { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PageTitle from '../../components/page-title/PageTitle';
import { Amplify, API } from 'aws-amplify';
import config from '../../aws-exports';

Amplify.configure(config);

function Login() {
    // ------------------------------------------------------------------------------------------

    // DB CONNECTION

    const [dbData, setDbData] = useState([]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = () => {
        API.get('UpriserDB', '/users', {})
            .then((response) => {
                setDbData(response.success);
            })
            .catch((error) => {
                console.log(error.response);
            });
    };

    // ------------------------------------------------------------------------------------------

    // RENDER

    return (
        <div className="About">
            <Container>
                <Row>
                    <Col>
                        <h1>
                            <PageTitle title="LOGIN" />
                        </h1>
                        <h3>dbData: {dbData}</h3>
                        <p> </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Login;
