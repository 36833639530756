import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import SplitText from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

function PageTitle({ title }) {
    //

    const [titleText, setTitleText] = useState('');
    let titleRef = useRef(null);

    useEffect(() => {
        let output = '';
        const increment = 700 / title.length;

        for (let i = 0; i < title.length; i++) {
            const weight = 100 + increment * i;
            output += `<span className="headline_char" style="font-weight:${weight};">${title[i]}</span>`;
        }

        setTitleText(output);

        const item = titleRef.current;
        gsap.fromTo(
            item,
            { opacity: '0' },
            {
                opacity: '1',
                scrollTrigger: {
                    trigger: item,
                    start: 'top 80%',
                    end: '+=300',
                    markers: false,
                },
            },
        );
    }, [title]);

    useEffect(() => {
        //console.log('animate');
        /* const item = titleRef.current;
        gsap.fromTo(
            titleRef.current.childNodes,
            { opacity: 0 },
            {
                opacity: 1,
                stagger: {
                    each: 0.1,
                    from: 0,
                    grid: 'auto',
                    ease: 'power2.inOut',
                    repeat: 1, 
                },
                scrollTrigger: {
                    trigger: item,
                    start: 'top center',
                    end: '+=300',
                    markers: false,
                },
            },
        ); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titleRef]);

    /*  let output = '';
    const increment = 700 / title.length;

    for (let i = 0; i < title.length; i++) {
        const weight = 100 + increment * i;
        output += `<span className="headline_char" style="font-weight:${weight};">${title[i]}</span>`;
    }

    return <div dangerouslySetInnerHTML={{ __html: output }} /> */

    /* useEffect(() => {
        let splitText = new SplitText(titleRef.current, { type: 'chars, words' });
        gsap.fromTo(
            splitText.chars,
            { fontWeight: 100, autoAlpha: 0 },
            { fontWeight: 900, duration: 2, stagger: -2 / title.length, autoAlpha: 1, ease: Linear.easeNone },
        ).pause(2);
    }, [title.length]); */

    /* return (
        <span className="title_text" ref={titleRef}>
            {output}
        </span>
    ); */

    return <div ref={titleRef} dangerouslySetInnerHTML={{ __html: titleText }} />;
}
export default PageTitle;
