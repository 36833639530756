import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';

import './partners.scss';

function Partners() {
    // ------------------------------------------------------------------------------------

    // CAROUSEL

    const partnersHolder = useRef();

    useLayoutEffect(() => {
        const logoArray = [
            'logo_itv.png',
            'logo_kensington_alridge_academy.png',
            'logo_cowshed.png',
            'logo_willows_high_school.png',
            'logo_leith.png',
            'logo_leith_academy.png',
            'logo_ima_home.png',
            'logo_brigshaw_high_school.png',
            'logo_saatchi.png',
            'logo_harris_academy.png',
        ];

        let index = -2;

        const swapLogos = () => {
            index += 2;
            if (index >= logoArray.length) index = 0;
            document.getElementById('logo_left').src = `images/logos/partner_logos/${logoArray[index]}`;
            document.getElementById('logo_right').src = `images/logos/partner_logos/${logoArray[index + 1]}`;
        };

        swapLogos();

        let ctx = gsap.context(() => {
            const boomTl = gsap
                .timeline()

                .from('.boom_1', { duration: 1, scale: 0.2, autoAlpha: 0, ease: 'back.out(1, 0.8)' }, 0.0)
                .from('.boom_2', { duration: 1, scale: 0.2, autoAlpha: 0, ease: 'back.out(1, 0.8)' }, 0.1)
                .from('.boom_3', { duration: 1, scale: 0.2, autoAlpha: 0, ease: 'back.out(1, 0.8)' }, 0.2)

                .to('.boom_1', { duration: 3, scale: 1.1, yoyo: true, ease: 'power2.in' }, 1.0)
                .to('.boom_2', { duration: 3, scale: 1.1, yoyo: true, ease: 'power2.in' }, 1.1)
                .to('.boom_3', { duration: 3, scale: 1.1, yoyo: true, ease: 'power2.in' }, 1.2)

                .to('.boom_1', { duration: 1, scale: 2, autoAlpha: 0, ease: 'power2.in' }, 3.0)
                .to('.boom_2', { duration: 1, scale: 2, autoAlpha: 0, ease: 'power2.in' }, 3.1)
                .to('.boom_3', { duration: 1, scale: 2, autoAlpha: 0, ease: 'power2.in' }, 3.2);

            const mainTl = gsap
                .timeline({ repeat: -1 })

                .from('.logo_left', { duration: 1, y: -10, autoAlpha: 0, ease: 'power2.out' }, 0.0)
                .from('.logo_right', { duration: 1, y: 10, autoAlpha: 0, ease: 'power2.out' }, 0.3)

                .add(boomTl, 1)

                .to('.logo_left', { duration: 1, autoAlpha: 0 }, 4.0)
                .to('.logo_right', { duration: 1, autoAlpha: 0 }, 4.5)

                .call(swapLogos, null, 5.5)
                .to('.boom', { duration: 0 }, 6);

            mainTl.play();
        }, [partnersHolder]);

        return () => ctx.revert(); // Cleanup
    }, []);

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <div className="partners_holder" ref={partnersHolder}>
            <div className="boom">
                <img src="images/boom/boom_1.svg" className="boom_1" alt="" />
                <img src="images/boom/boom_2.svg" className="boom_2" alt="" />
                <img src="images/boom/boom_3.svg" className="boom_3" alt="" />
            </div>

            <div className="logo_left">
                <img id="logo_left" src="" className="logo" alt="" />
            </div>

            <div className="logo_right">
                <img id="logo_right" src="" className="logo" alt="" />
            </div>
        </div>
    );
}

export default Partners;
