import { useState } from 'react';
import { Image, Nav, Navbar, Container } from 'react-bootstrap';
import styles from './index.module.scss';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

function Header({ routes }) {
    // ------------------------------------------------------------------------------------

    // MOBILE COLLAPSED STATE

    const [open, setOpen] = useState(false);

    // ------------------------------------------------------------------------------------

    // ADD VERTICAL OFFSET TO ANCHOR

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -68;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    };

    // ------------------------------------------------------------------------------------

    // ADD EITHER A SCROLL LINK OR A ROUTE

    const addLink = (item, index, menu) => {
        //
        if (item.name === 'Home') return;
        if (item.menu !== menu) return;

        const getIcon = () => {
            if (item.icon === 'faUser') return <FontAwesomeIcon icon={faUser} />;
        };

        if (item.type === 'hash') {
            return (
                <HashLink smooth key={index} to={item.path} scroll={scrollWithOffset} onClick={() => setOpen(false)}>
                    {getIcon()} {item.name}
                </HashLink>
            );
        }
        return (
            <NavLink key={index} to={item.path}>
                {getIcon()} {item.name}
            </NavLink>
        );
    };

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <Navbar
            collapseOnSelect
            variant="dark"
            fixed="top"
            expand="md"
            className={`${styles.navbar} navbar-static-top`}
        >
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setOpen(!open)} aria-expanded={open} />
                <Navbar.Brand href="/#top">
                    <Image className={styles.headerlogo} src="./images/logos/logo_green.png" />
                </Navbar.Brand>
                <Navbar.Collapse in={open} id="basic-navbar-nav" className={styles.menubar}>
                    <Nav className={styles.main_nav}>{routes.map((item, index) => addLink(item, index, 'main'))}</Nav>
                    <Nav className={styles.right_nav}>{routes.map((item, index) => addLink(item, index, 'right'))}</Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
