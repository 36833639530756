import { Carousel } from 'react-bootstrap';
import useWindowSize from '../../utils/useWindowSize';
import styles from './index.module.scss';
import './carousel.scss';

function TestimonialsCarousel() {
    const platform = useWindowSize()[0] >= 768 ? 'desktop' : 'mobile';

    const setFontsize = (small, large) => {
        let fontSize = platform === 'mobile' ? small : large;
        return fontSize;
    };

    return (
        <Carousel className={`testimonial_carousel ${styles.testimonial_carousel}`}>
            {}
            <Carousel.Item>
                <div className={styles.carousel_wrapper}>
                    <div className={styles.quote_wrapper}>
                        <p className={`${styles.quote}`} style={{ fontSize: `${setFontsize(22, 30)}px` }}>
                            “It made me realise, when we were doing things like making our own advert, how creative I
                            can be. A lot of other people too, I didn’t know they were that creative, I didn’t know I
                            was that creative.”
                        </p>
                        <p className="quote_atttibute">Jack. Sixth Former, Harris Academy Greenwich</p>
                    </div>
                </div>
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            {}
            <Carousel.Item>
                <div className={styles.carousel_wrapper}>
                    <div className={styles.quote_wrapper}>
                        <p className={`${styles.quote}`} style={{ fontSize: `${setFontsize(22, 30)}px` }}>
                            “I realised that I don’t have to follow the typical route, go to school, go to university,
                            get a typical job. It made me realise I can do something I actually like, and that can be
                            creative."
                        </p>
                        <p className="quote_atttibute">MIRIAM. SIXTH FORMER HARRIS ACADEMY GREENWICH</p>
                    </div>
                </div>
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            {}
            <Carousel.Item>
                <div className={styles.carousel_wrapper}>
                    <div className={styles.quote_wrapper}>
                        <p className={`${styles.quote}`} style={{ fontSize: `${setFontsize(22, 24)}px` }}>
                            “The impact of the programme is almost indescribable. It’s built to the rhythm of the school
                            and real world curriculum. It’s taken some of our lowest performers and boosted confidence
                            and energy. It’s transformed entire year groups. It’s turbo-charged our teaching community
                            and demonstrably contributed to us gaining an outstanding Ofsted scores. Every school in the
                            UK needs this programme.”
                        </p>
                        <p className="quote_atttibute">
                            GEORGINA CHARLES, VICE PRINCIPAL HARRIS ACADEMY CHAFFORD HUNDRED
                        </p>
                    </div>
                </div>
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            {}
            <Carousel.Item>
                <div className={styles.carousel_wrapper}>
                    <div className={styles.quote_wrapper}>
                        <p className={`${styles.quote}`} style={{ fontSize: `${setFontsize(22, 24)}px` }}>
                            “It’s all about sustainability. I’ve been in charge of careers for five years and companies
                            will come in, but often won’t be able to commit long term. For our students to build
                            relationships and really understand what it’s like to work in those industries, it needs to
                            be a sustainable partnership between us and the company. For me this has been the key
                            difference and that has been amazing.”
                        </p>
                        <p className="quote_atttibute">
                            Natalie Dormer, Assistant Principal at Harris Academy Greenwich
                        </p>
                    </div>
                </div>
                <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            {}
        </Carousel>
    );
}

export default TestimonialsCarousel;
