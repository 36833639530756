import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Container } from 'react-bootstrap';
import CookieConsent from 'react-cookie-consent';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Home from './routes/home/Home';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Login from './routes/login/Login';
import Playbook from './routes/playbook/Playbook';

import LegalsModal from './components/legals-modal/LegalsModal';

// ------------------------------------------------------------------------------------------

// GOOGLE ANALYITICS

import ReactGA from 'react-ga4';
ReactGA.initialize('G-DJQM1V3SEF');
ReactGA.send('pageview');

// ------------------------------------------------------------------------------------------

// ROUTES ETC

const routes = [
    { path: '/', name: 'Home', type: 'hash', menu: 'none', icon: '', Component: Home },
    { path: '/#top', name: 'Intro', type: 'hash', menu: 'main', icon: '', Component: Home },
    { path: '/#programme', name: 'Programme', type: 'hash', menu: 'main', icon: '', Component: Home },
    { path: '/#boom', name: '3 Pillars', type: 'hash', menu: 'main', icon: '', Component: Home },
    { path: '/#partners', name: 'Partners', type: 'hash', menu: 'main', icon: '', Component: Home },
    { path: '/#testimonials', name: 'Testimonials', type: 'hash', menu: 'main', icon: '', Component: Home },
    { path: '/#contact', name: 'Contact', type: 'hash', menu: 'main', icon: '', Component: Home },
    { path: '/login', name: 'Login', type: 'page', menu: 'right', icon: 'faUser', Component: Login },
    { path: '/playbook', name: 'Playbook', type: 'page', menu: 'none', icon: '', Component: Playbook },
];

// ------------------------------------------------------------------------------------------

function App() {
    // ------------------------------------------------------------------------------------------

    // CONDITIONALLY SHOW NAVBAR

    const [showNav, setShowNav] = useState(true);

    // ------------------------------------------------------------------------------------------

    // MODALS

    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');

    const closeModal = () => setModalOpen(false);

    const showModal = (type) => {
        setModalType(type);
        setModalOpen(true);
    };

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <div className="App">
            <HelmetProvider>
                <Helmet>
                    <title>UPRISER by Saatchi & Saatchi</title>
                    <meta
                        name="description"
                        content="Plugging the next generation into the creative industries. We match-make creative companies with UK secondary schools to engineer meaningful, sustained partnerships."
                    />
                </Helmet>
            </HelmetProvider>
            <LegalsModal modalOpen={modalOpen} closeModal={closeModal} modalType={modalType} />

            <Container fluid className="">
                {showNav && <Header routes={routes} exact />}

                <div className="content">
                    <Routes>
                        {routes.map(({ name, path, type, Component }) => (
                            <Route
                                key={name}
                                path={path}
                                type={type}
                                element={<Component showModal={showModal} setShowNav={setShowNav} />}
                            ></Route>
                        ))}
                    </Routes>
                    <Footer showModal={showModal} className="fixed-bottom" />
                </div>
            </Container>

            <CookieConsent
                className="cookie_consent_container"
                debug={false}
                overlay={true}
                location="bottom"
                buttonText="I accept"
                cookieName="upriserCookieConsent"
                buttonClasses="btn"
                expires={365}
                containerClasses="cookie_consent"
            >
                We use essential cookies to improve user experience and analyse website traffic. By clicking 'Accept',
                you agree to our website's cookie use as described in our{' '}
                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => showModal('cookies')}>
                    Cookie Policy
                </span>
            </CookieConsent>
        </div>
    );
}

export default App;

// ------------------------------------------------------------------------------------------
