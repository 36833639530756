import React, { useEffect } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import PlaybookMobile from './PlaybookMobile';

import './playbook.scss';

//const file = '/downloads/Playbook.pdf';
const file = 'https://upriser-files.s3-eu-west-2.amazonaws.com/playbook/Playbook.pdf';

function Playbook(props) {
    //
    useEffect(() => {
        props.setShowNav(false);
        document.title = 'Upriser Playbook';
        /* const downloadBtn = document.getElementById('downloads');
        downloadBtn.style.display = 'none'; */

        console.log('isBrowser: ' + isBrowser);
        console.log('isMobile: ' + isMobile);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <BrowserView>
                <div className="pdf_holder">
                    <embed
                        src={file}
                        width="100%"
                        height="100%"
                        type="application/pdf"
                        onContextMenu={(e) => e.preventDefault()}
                    />
                </div>
            </BrowserView>
            <MobileView>
                <PlaybookMobile />
            </MobileView>
        </>
    );
}

export default Playbook;
