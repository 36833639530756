import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import './footer.scss';

function Footer(props) {
    return (
        <Container id="footer" fluid className="footer greyBg">
            <Container className="">
                <Row>
                    <Col xs="12" md="3" className="footer_col">
                        <a href="https://saatchi.co.uk/" target="_blank" rel="noreferrer">
                            <Image className="sns_logo" src="./images/logos/Saatchi-Logo_White.svg" width="60%"></Image>
                        </a>
                    </Col>

                    <Col xs="12" md="3" className="footer_col">
                        <p onClick={() => props.showModal('privacy')}>Privacy Policy</p>
                    </Col>

                    <Col xs="12" md="3" className="footer_col">
                        <p onClick={() => props.showModal('terms')}>Terms of use</p>
                    </Col>

                    <Col xs="12" md="3" className="footer_col">
                        <p onClick={() => props.showModal('cookies')}>Cookie policy</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Footer;
