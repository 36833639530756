import React, { useState } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Amplify, API } from 'aws-amplify';
import awsmobile from '../../aws-exports';
Amplify.configure(awsmobile);

function ContactForm(props) {
    // ------------------------------------------------------------------------------------

    // FORM POSTING AND STATUS

    const [formPosting, setFormPosting] = useState(false);
    const [formPosted, setFormPosted] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [formData, setFormData] = useState({
        Firstname: '',
        Lastname: '',
        Email: '',
        Company: '',
        Message: '',
        AllowUpdates: false,
    });

    // CHANGES MADE TO THE FORM FIELDS

    const handleChange = (event) => {
        const value = event.target.name === 'AllowUpdates' ? event.target.checked : event.target.value;
        setFormData((formData) => ({
            ...formData,
            [event.target.name]: value,
        }));
        validateForm();
    };

    // VALIDATE FORM

    const validateForm = () => {
        let errors = {};
        if (!formData.Firstname) errors.Firstname = 'Required.';
        if (!formData.Message) errors.Message = 'Required.';
        if (!formData.Email) errors.Email = 'Required.';
        if (!/\S+@\S+\.\S+/.test(formData.Email)) errors.Email = 'Invalid.';
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setFormValid(true);
            return true;
        }
        setFormValid(false);
        return false;
    };

    // ALL CLEAR, START SEND PROCESS, DISABLE BUTTONS ETC

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) setFormPosting(true);
        const response = await postData();
        console.log('response: ', response);
        setFormPosting(false);
        setFormPosted(true);
    };

    // AWS SES POST

    const postData = async () => {
        const apiName = 'UpriserExpress';
        const path = '/items/sendContactEmails';
        const myInit = {
            body: { formData },
            headers: {}, // OPTIONAL
        };

        return await API.post(apiName, path, myInit);
    };

    // ------------------------------------------------------------------------------------

    // T&C CHECKBOX AND MODAL

    const [tncChecked, setTncChecked] = useState(false);
    const tncChange = () => {
        setTncChecked((current) => !current);
    };

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <Form className={`form  ${styles.form_wrapper}`} onSubmit={handleSubmit}>
            <Row className={styles.nopad}>
                <Col xs="12" md="6">
                    <Form.Group controlId="formFirstName">
                        <Form.Label>
                            First Name
                            {formErrors.Firstname && <span className="text-danger"> {formErrors.Firstname}</span>}
                        </Form.Label>
                        <Form.Control
                            className={styles.field}
                            name="Firstname"
                            type="text"
                            placeholder=""
                            value={formData.Firstname}
                            onChange={handleChange}
                            onKeyUp={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" md="6">
                    <Form.Group controlId="formLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            className={styles.field}
                            name="Lastname"
                            type="text"
                            placeholder=""
                            value={formData.Lastname}
                            onChange={handleChange}
                            onKeyUp={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className={styles.nopad}>
                <Col xs="12" md="6">
                    <Form.Group controlId="formEmail">
                        <Form.Label>
                            Email Address
                            {formErrors.Email && <span className="text-danger"> {formErrors.Email}</span>}
                        </Form.Label>
                        <Form.Control
                            className={styles.field}
                            name="Email"
                            type="email"
                            placeholder=""
                            value={formData.Email}
                            onChange={handleChange}
                            onKeyUp={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" md="6">
                    <Form.Group controlId="formCompany">
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                            className={styles.field}
                            name="Company"
                            type="text"
                            placeholder=""
                            value={formData.Company}
                            onChange={handleChange}
                            onKeyUp={handleChange}
                        />
                        {formErrors.Company && <p className="text-danger">{formErrors.Company}</p>}
                    </Form.Group>
                </Col>
            </Row>
            <Row className={styles.nopad}>
                <Col>
                    <Form.Group controlId="formMessage">
                        <Form.Label>
                            Message
                            {formErrors.Message && <span className="text-danger"> {formErrors.Message}</span>}
                        </Form.Label>
                        <Form.Control
                            className={styles.field}
                            name="Message"
                            as="textarea"
                            rows={3}
                            placeholder=""
                            value={formData.Message}
                            onChange={handleChange}
                            onKeyUp={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className={styles.nopad}>
                <Form.Group className={`${styles.termscheckbox_line}`} controlId="formTermsCheckbox">
                    <Form.Check type="checkbox" name="termsCheckbox" label="" value={tncChecked} onChange={tncChange} />
                    <span>
                        I have read the{' '}
                        <span className={styles.terms_link} onClick={() => props.showModal('privacy')}>
                            privacy policy
                        </span>
                    </span>
                </Form.Group>
                <Form.Group className={`${styles.termscheckbox_line}`} controlId="formTermsCheckbox">
                    <Form.Check type="checkbox" name="AllowUpdates" label="" onClick={handleChange} />
                    <span>I am happy for Saatchi & Saatchi to share updates with me about this programme.</span>
                </Form.Group>
                <Col>
                    <div className={styles.submit_progress}>
                        <Button
                            disabled={!tncChecked || formPosting || formPosted || !formValid}
                            className={`${styles.field} ${styles.submit_btn}`}
                            variant="secondary"
                            type="submit"
                        >
                            Submit
                        </Button>
                        <FontAwesomeIcon
                            icon={faSpinner}
                            className={formPosting ? 'fa-pulse' : ''}
                            style={{ display: formPosting ? 'block' : 'none' }}
                        />
                        {!tncChecked && <span style={{ marginLeft: '10px' }}>Please agree to the Privacy Policy.</span>}
                        {formPosted && (
                            <span style={{ marginLeft: '10px' }}>
                                Thanks for your submission, we will get back to you shortly.
                            </span>
                        )}
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

export default ContactForm;
