import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import './pdf_viewer.scss';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

const options = {
    cMapUrl: 'cmaps/',
    standardFontDataUrl: 'standard_fonts/',
};

function PlaybookMobile() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    //const file = '/downloads/Playbook.pdf';
    const file = 'https://upriser-files.s3-eu-west-2.amazonaws.com/playbook/Playbook.pdf';

    const onDocumentLoadSuccess = ({ numPages }) => {
        console.log('onDocumentLoadSuccess');
        setNumPages(numPages);
        setPageNumber(1);
    };

    const changePage = (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    return (
        <>
            <div className="pdf_viewer">
                <div className="navbar">
                    <div className="pagination_count">
                        Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                    </div>
                    <div className="pagination_buttons">
                        <div className="pagination_btn" type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                            Previous
                        </div>
                        <div
                            className="pagination_btn"
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                        >
                            Next
                        </div>
                    </div>
                </div>
                <div className="viewer">
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                </div>
            </div>
        </>
    );
}

export default PlaybookMobile;
