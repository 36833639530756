import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../page-title/PageTitle';
import { Amplify, API } from 'aws-amplify';
import awsmobile from '../../aws-exports';

import styles from '../contact-form/index.module.scss';
import './download_modal.scss';

Amplify.configure(awsmobile);

function DownloadModal(props) {
    // ------------------------------------------------------------------------------------

    // FORM POSTING AND STATUS

    const [formPosting, setFormPosting] = useState(false);
    const [formPosted, setFormPosted] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [formData, setFormData] = useState({
        Firstname: '',
        Lastname: '',
        Email: '',
        Company: '',
        Message: '',
    });

    // CHANGES MADE TO THE FORM FIELDS

    const handleChange = (event) => {
        setFormData((formData) => ({
            ...formData,
            [event.target.name]: event.target.value,
        }));
        validateForm();
    };

    // CONSTATNTLY VALIDATE FORM

    const validateForm = () => {
        let errors = {};
        if (!formData.Email) errors.Email = 'Required.';
        if (!/\S+@\S+\.\S+/.test(formData.Email)) errors.Email = 'Invalid.';
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setFormValid(true);
            return true;
        }
        setFormValid(false);
        return false;
    };

    // ALL CLEAR, START SEND PROCESS, DISABLE BUTTONS ETC

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) setFormPosting(true);
        const response = await postData();
        console.log('response: ', response);
        setFormPosting(false);
        setFormPosted(true);
    };

    // SES POST

    const postData = async () => {
        const apiName = 'UpriserExpress';
        const path = '/items/sendDownloadLink';
        const myInit = {
            body: { formData },
            headers: {}, // OPTIONAL
        };

        return await API.post(apiName, path, myInit);
    };

    // ------------------------------------------------------------------------------------

    const closeSelf = () => {
        props.handleDownloadModalClose();
        setFormPosted(false);
        setFormErrors(false);
    };

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <Modal show={props.showDownloadModal} animation={true} centered className={`download_modal`}>
            <Modal.Header closeButton onClick={closeSelf}>
                <h2>
                    <PageTitle title="Download PDF" />
                </h2>
            </Modal.Header>
            <Form className={`form  ${styles.form_wrapper}`} onSubmit={handleSubmit}>
                <Row>
                    <p>Please enter you email address here so we can send you a link to the document</p>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>
                                Email address
                                {formErrors.Email && <span className="text-danger"> ! {formErrors.Email}</span>}
                            </Form.Label>
                            <Form.Control
                                className={styles.field}
                                name="Email"
                                type="email"
                                placeholder=""
                                value={formData.Email}
                                onChange={handleChange}
                                onKeyUp={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className={styles.submit_progress}>
                            <Button
                                disabled={formPosting || formPosted || !formValid}
                                className={`${styles.field} ${styles.submit_btn}`}
                                variant="secondary"
                                type="submit"
                            >
                                Submit
                            </Button>
                            <FontAwesomeIcon
                                icon={faSpinner}
                                className={formPosting ? 'fa-pulse' : ''}
                                style={{ display: formPosting ? 'block' : 'none' }}
                            />
                            {formPosted && (
                                <span style={{ marginLeft: '10px' }}>
                                    Thanks. Please check your inbox for the link.
                                </span>
                            )}
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default DownloadModal;
