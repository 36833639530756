import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PageTitle from '../page-title/PageTitle';

import './legals_modal.scss';

function LegalsModal(props) {
    // ------------------------------------------------------------------------------------

    // CONTENT SWITCHING

    const modalType = props.modalType;
    const [textContent, setTextContent] = useState();

    let pageTitle = '';
    if (modalType === 'privacy') pageTitle = 'Privacy Policy';
    if (modalType === 'terms') pageTitle = 'Terms & Conditions';
    if (modalType === 'cookies') pageTitle = 'Cookie Policy';

    useEffect(() => {
        if (modalType === '') return;
        const contentUrl = `./legals/${modalType}.html`;

        fetch(contentUrl)
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                setTextContent(data);
            });
    }, [modalType]);

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <Modal
            show={props.modalOpen}
            onHide={() => props.closeModal()}
            animation={true}
            className={`legals_modal`}
            size="lg"
        >
            <Modal.Header closeButton onClick={() => props.closeModal()}>
                <h1>
                    <PageTitle title={pageTitle} />
                </h1>
            </Modal.Header>
            <Modal.Body className="legals_modal" dangerouslySetInnerHTML={{ __html: textContent }}></Modal.Body>
        </Modal>
    );
}

export default LegalsModal;
